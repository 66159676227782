import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
} from '@angular/router';

import { UserLayoutComponent } from './layout/user-layout.component';
import { UserDashboardComponent } from './pages';

import {
  ActionLogsComponent,
  AuthGuard,
  ProjectCreateComponent,
  ProjectEditComponent,
  ProjectsComponent,
  ProjectViewComponent,
  TeamCreateComponent,
  TeamsComponent,
  TeamViewComponent,
  TicketCreateComponent,
  TicketExistsGuard,
  TicketsComponent,
  TicketViewComponent,
} from '../shared';
import {
  NotFoundComponent,
  TeamInviteComponent,
  UserProfileComponent,
} from '../shared/components';

const routes: Routes = [
  {
    path: '',
    component: UserLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        children: [],
        canActivate: [],
      },
      {
        path: 'dashboard',
        component: UserDashboardComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'action-logs',
        component: ActionLogsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'profile',
        component: UserProfileComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'projects',
        component: ProjectsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'projects/add',
        component: ProjectCreateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'projects/:id',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: ProjectViewComponent,
          },
          {
            path: 'edit',
            component: ProjectEditComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'projects/:projectId/tickets/add',
        component: TicketCreateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'teams',
        component: TeamsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'teams/add',
        component: TeamCreateComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'teams/:id',
        component: TeamViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'teams/:id/invite',
        component: TeamInviteComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'tickets',
        component: TicketsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'tickets/:id',
        component: TicketViewComponent,
        canActivate: [AuthGuard, TicketExistsGuard],
      },
      {
        path: 'tickets/:id/edit',
        component: TicketCreateComponent,
        canActivate: [AuthGuard, TicketExistsGuard],
      },
      {
        path: '**',
        component: NotFoundComponent,
        canActivate: [],
        data: { noTitle: true },
      },
    ],
    data: { noAdmin: true, type: 'user' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserRoutingModule {
}
