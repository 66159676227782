import { Component } from '@angular/core';

import { AppService } from '../../../core';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class UserDashboardComponent {
  constructor(private appService: AppService) {
    this.appService.title = 'pages.dashboard';
    this.appService.active = 'dashboard';
  }
}
