<div class="mx-3 mb-3">
  <div class="row my-3">
    <div class="col-12">
      <mat-card>
        <mat-card-header>
          <h2>
            <strong>{{'filters.actionLogs.label' | translate}}</strong>
          </h2>
        </mat-card-header>
        <mat-card-content>
          <app-action-logs></app-action-logs>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>